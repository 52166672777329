@import '../../../themes/variables.scss';

.page-title {
  text-align: center;
  color: $theme-color-secondary;
  font-size: 44px;
  padding-top: 20px;
  padding-bottom: 10px;
  font-weight: 900;
}
