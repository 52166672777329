@import '../../../themes/variables.scss';

.title-pages {
  width: 100%;

  color: $gray;

  h1 {
    text-align: center;
    color: $theme-color-secondary;
    font-size: 44px;
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: 900;
  }
}
