@import '../../themes/variables.scss';
@import '../../themes/placeholders.scss';

.notifications {
  flex: 1;
  @extend %flex-content-center;

  svg {
    color: $black;
  }

  #check-notifications {
    display: none;

    &:checked {
      ~ label .turn-off-the-light-notifications {
        visibility: visible;
        opacity: 0.6;
      }

      ~ .menu-notifications {
        right: 0;
      }
    }
  }

  .turn-off-the-light-notifications {
    @extend %full-screen;
    background-color: $black;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0.2s linear;
  }

  .menu-notifications {
    @extend %retratil-element;
    width: 70%;
    right: -70%;
    background-color: $white;
    transition: all 0.2s linear;

    @media screen and (min-width: 993px) {
      right: -30%;
      width: 30%;
    }
  }
  #chamados {
    color: #fff;
    background-color: $danger;
    border-radius: 10px;
    width: 18px;
    height: 18px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    display: block;
    position: absolute;
    margin-top: 10px;
    margin-right: 15px;
    //padding-top: 2px;
  }
}
