@import '../../themes/variables.scss';
@import '../../themes/placeholders.scss';

.drawer {
  flex: 1;
  z-index: 9999;
  @extend %flex-content-center;

  .bars {
    color: $black;
    margin-top: 13px;
  }

  #check {
    display: none;
    &:checked {
      ~ label .turn-off-the-light {
        visibility: visible;
        opacity: 0.6;
      }

      ~ .menu {
        left: 0;
        position: fixed;
      }
    }
  }

  .turn-off-the-light {
    @extend %full-screen;
    background-color: $black;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0.2s linear;
  }

  .menu {
    @extend %retratil-element;
    left: -70%;
    width: 70%;
    background-color: $white;
    transition: all 0.2s linear;

    @media screen and (min-width: 993px) {
      left: -30%;
      width: 30%;
    }
  }
}
