@import './variables.scss';
@import './fonts.scss';

$theme-colors: (
  'primary': $primary,
  'danger': $danger,
  'secondary': $secondary,
  'theme-color-primary': $theme-color-primary,
  'theme-color-secondary': $theme-color-secondary,
  'turn-off-the-light': $turn-off-the-light,
  'white': $white,
  'black': $black,
  'gray': $gray,
  'gray-light': $gray-light,
  'gray-lighter': $gray-lighter,
);

@import '~bootstrap/scss/bootstrap';

body,
html {
  font-family: $font-family;
  background-color: $brackground;
}

.btn {
  border-radius: 10px !important;
}

.content {
  display: flex;
  overflow: auto;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.modal-content {
  border-radius: 10px;

  .modal-header {
    border-bottom: none;
    .close {
      font-size: 30px;
      font-weight: 900;
      color: $primary !important;
      opacity: 1;
    }
  }

  .modal-footer {
    border-top: none;
  }
}
