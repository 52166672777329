@import '../../../../../themes/variables.scss';

.wrapper-content-pedido-materiais {
  .content-pedido-materiais {
    position: relative;
    min-height: calc(100vh - 344px);
    // max-width: 1024px;
    margin: auto;

    // @media screen and (min-width: 322px) {
    //   min-height: calc(100vh - 140px);
    // }
  }
}
