@import '../../../../../../themes/_variables';

.wrapper-filtro {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 5px;
  background-color: $theme-color-secondary;
  border-radius: 5px;
  color: $white;
  box-shadow: 0px 3px 6px $black2;

  .filtro {
    flex: 1;
    padding: 5px;

    button {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.8rem;
      text-align: left;
      background-color: #ffffff !important;
      color: black !important;
      border: 1px solid $theme-color-secondary !important;
      box-shadow: none !important;
      border-radius: 3px !important;
      width: 100%;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 0.8rem;
      font-weight: 900;
    }

    .option {
      width: 100%;
    }

    .dropdown-menu {
      padding: 8px;
      width: 100%;

      label {
        font-size: 0.8rem;
      }

      .btn-container {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .btn-aplicar {
          background-color: $theme-color-secondary !important;
          color: white !important;
        }
        .btn-aplicar:hover {
          background-color: $secondary !important;
          color: white !important;
        }

        button {
          display: initial;
          text-align: center;
          font-size: 0.8rem;
          width: 100%;
        }
      }
    }
  }
}

.dropdonwn-scrollable {
  overflow-y: scroll;
  max-height: 250px;
  /* width */
  &::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #5555553e;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #6363636e;
  }
}
