@import '../../themes/variables.scss';
@import '../../themes/mixins.scss';
@import '../../themes/placeholders.scss';

.menu {
  max-width: 370px;

  .header-menu {
    background-color: $primary;
    height: 220px;
    @extend %flex-content-center;
    p,
    h3 {
      margin: 0;
      padding: 0;
      color: $white;
    }
    p {
      font-size: 1.2em;
      font-weight: 200;
    }
    h3 {
      font-size: 1.6em;
    }
    > div {
      @extend %flex-content-center;
      flex-direction: column;
      img {
        max-width: 200px;
      }
    }
  }

  .body-menu {
    height: calc(100vh - 220px);
    overflow: auto;

    ul {
      list-style: none;
      padding-left: 50px;
      padding-top: 18px;

      li {
        margin: 20px auto;
        display: flex;
        transition: all 0.2s linear;
        font-size: 1.3em;
        color: $black;
        font-weight: 500;
        :hover {
          color: $primary;
        }

        a {
          color: $black;
          text-decoration: none;

          span {
            margin-left: 15px;
          }
          img {
            color: $gray;
            margin-top: -11px;
            width: 30px;
            :hover {
              color: primary;
            }
          }

          svg {
            color: $gray;
            margin-top: -11px;
            width: 35px;
            :hover {
              color: primary;
            }
          }
        }
      }
    }
    .logout-button {
      padding: 10px 10px 20px 50px;
      button {
        width: 100%;
        max-width: 240px;
        border-radius: 10px;
      }
    }
  }
}
