@import '../../../../themes/variables.scss';

.wrapper-content-tipo-negocio{
  min-height: calc(100vh - 200px);
}

.content-tipo-negocio{
  padding-bottom: 2rem;
  div {
    .negocio-content {
      // padding: 25px;
      background-color: $primary;
      border-radius: 20px;
      color: white;
      min-height: 250px;
      cursor: pointer;
      .negocio-nome {
        display: block;
        font-size: 30px;
        text-align: center;;
        svg {font-size: 100px;padding-bottom: 15px}

      }
      .negocio-hover{
        display: none;
      }
      &:hover{
        .negocio-hover{
          display: block!important;
        }
        .negocio-nome{
          display: none!important;
        }
      }
    }
  }
}
