@import '../../../themes/variables.scss';
.content_relatorios{
  margin: 10px;
  height: 100%;

  h1.relatorio-title{
    text-align: center;
    font-size: 63px;
    color: $theme-color-secondary;
    padding-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid;
    border-bottom-color: $gray;
  }

  .placeHold{
    min-height: 600px;
    height: 100%;
  }

  .iframe-container-tableau {
    overflow: hidden;
    height: 2600px;
    position: relative;
}

    .iframe-container-tableau iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
